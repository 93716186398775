import {
  AddressDTO,
  CaseDTO,
  LocationDTO,
  OverviewItemDTO,
} from "@/common/dto";
import {
  Advisory,
  Country,
  DamageType,
  DecisionEnum,
  Kind,
  Ownership,
  RentalDecision,
  RepairDecision,
  Reporter,
  Roadworthiness,
  Status,
  Title,
} from "@/common/enums";
import moment from "moment";
import i18n from "@/ui/i18n";

export class DtoHelpers {
  static empty_case(): CaseDTO {
    return new CaseDTO(
      "",
      "",
      "",
      "",
      new Date(),
      "",
      new Date(),
      new Date(),
      "",
      "",
      Status.WORK_IN_PROGRESS,
      Reporter.NONE,
      Kind.NONE,
      DtoHelpers.empty_address(),
      "",
      DecisionEnum.NONE,
      "",
      "",
      "",
      Ownership.NONE,
      "",
      Roadworthiness.NONE,
      "",
      "",
      "",
      DtoHelpers.empty_address(),
      DecisionEnum.NONE,
      DtoHelpers.empty_address(),
      "",
      Country.NONE,
      null,
      "",
      "",
      DecisionEnum.NONE,
      Country.NONE,
      DecisionEnum.NONE,
      "",
      "",
      DecisionEnum.NONE,
      RentalDecision.NONE,
      RepairDecision.NONE,
      null,
      DecisionEnum.NONE,
      Advisory.NONE,
      "",
      "",
      "",
      DtoHelpers.empty_address(),
      "",
      DecisionEnum.NONE,
      0,
      DamageType.THIRD_PARTY_LIABILITY,
      [],
      "",
      "",
      "",
      DecisionEnum.NONE,
      "",
      null,
      "",
      "",
      "",
      "",
      LocationDTO.empty(),
      ""
    );
  }

  static empty_address(): AddressDTO {
    return new AddressDTO(
      "",
      Title.NONE,
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      "",
      Country.NONE,
      "",
      "",
      ""
    );
  }

  static generate_case_title(aCase: CaseDTO): string {
    let name = "";
    if (
      aCase.injuredParty !== null &&
      aCase.injuredParty !== undefined &&
      (aCase.injuredParty.lastName !== "" || aCase.injuredParty.company !== "")
    ) {
      if (aCase.injuredParty.title === Title.COMPANY) {
        name = aCase.injuredParty.company;
      } else {
        name = aCase.injuredParty.lastName;
      }
    }

    return this.generate_title(
      name,
      aCase.vehicleLicensePlate,
      aCase.accidentDate,
      aCase.damageType
    );
  }

  static generate_overview_title(item: OverviewItemDTO): string {
    const name = item.company !== "" ? item.company : item.lastName;

    return this.generate_title(
      name,
      item.vehicleLicensePlate,
      item.accidentDate,
      item.damageType
    );
  }

  static generate_title(
    name: string,
    licensePlate: string,
    accidentDate: Date | null,
    damageType: DamageType
  ): string {
    const items = [] as string[];

    if (name !== "") {
      items.push(name);
    }

    if (licensePlate !== "") {
      items.push(licensePlate);
    }

    const date = moment(accidentDate);
    if (date.isValid()) {
      items.push(date.format("DD.MM.YYYY"));
    } else {
      items.push(i18n.t("UI.general.dateInvalid"));
    }

    if (damageType !== undefined && damageType !== DamageType.NONE) {
      items.push(i18n.t("UI.enum.damage_type." + damageType));
    }

    return items.join(" - ");
  }
}
