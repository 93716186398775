import { LocationType } from "@/common/enums";

export class LocationDTO {
  locationCode: string;
  name: string;
  partner: string;
  mainLocation: boolean;
  type: LocationType;
  hasApiIntegration: boolean;

  constructor(
    locationCode: string,
    name: string,
    partner: string,
    mainLocation: boolean,
    type: LocationType,
    hasApiIntegration: boolean
  ) {
    this.locationCode = locationCode;
    this.name = name;
    this.partner = partner;
    this.mainLocation = mainLocation;
    this.type = type;
    this.hasApiIntegration = hasApiIntegration;
  }

  static empty(): LocationDTO {
    return new LocationDTO("", "", "", false, LocationType.LOCATION, false);
  }
}
