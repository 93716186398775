
import Vue from "vue";
import { TimatoForm } from "@/ui/components/common/";
import { NamedRoutes } from "@/ui/router";
import { userModuleMeta } from "@/ui/store/user";
import { mapGetters } from "vuex";
import { Action, Advisory } from "@/common/enums";
import { CasesHttpClient } from "@/common/http";
import i18n from "@/ui/i18n";
import { BuefyHelpers, DtoHelpers } from "@/common/helpers";
import { LocationDTO, UserDetails } from "@/common/dto";

export default Vue.extend({
  components: { TimatoForm },
  data() {
    return {
      caseForm: DtoHelpers.empty_case(),
      loaded: false,
      saving: false,
      errors: [],
      Action,
    };
  },
  methods: {
    save: function () {
      if (
        !this.caseForm.locationId ||
        !this.caseForm.locationId.includes("/")
      ) {
        return this.showAlert();
      }

      this.saving = true;
      new CasesHttpClient()
        .createCase(this.caseForm)
        .then((res) => {
          this.$router.push({
            name: NamedRoutes.DETAIL,
            params: { caseId: res.id },
          });
        })
        .catch((err) => {
          this.saving = false;
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.cases.create"),
            err.message
          );
        });
    },
    backToOverview: function () {
      this.$router.push({
        name: NamedRoutes.OVERVIEW,
      });
    },
    showAlert: function () {
      const options = {
        title: i18n.t("UI.dialog.locationError.title"),
        message: i18n.t("UI.dialog.locationError.message"),
        type: "is-danger",
        hasIcon: true,
        icon: "exclamation-triangle",
        confirmText: i18n.t("UI.general.confirm"),
      };
      this.$buefy.dialog.alert(options);
    },
  },
  computed: {
    ...mapGetters({
      details: userModuleMeta.getters.getDetails,
    }),
  },
  created() {
    window.scrollTo(0, 0);
    const locations = Array.from(this.details.locations) as LocationDTO[];

    this.caseForm.createdBy = this.details.username;
    this.caseForm.lastModifiedBy = this.details.username;

    if (UserDetails.isAppraiser(this.details)) {
      this.caseForm.expertOfficeCode = locations[0];
      this.caseForm.advisory = Advisory.YES;
      this.caseForm.expertEmployeeCode = this.details.username;
    } else {
      this.caseForm.locationId =
        locations.length == 1 && locations[0].locationCode.includes("/")
          ? locations[0].locationCode
          : "";
    }

    this.loaded = true;
  },
});
