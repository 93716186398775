
import Vue from "vue";
import { AttachmentDTO } from "@/common/dto";
import fileDownload from "js-file-download";
import { AttachmentHttpClient } from "@/common/http";
import i18n from "@/ui/i18n";
import { BuefyHelpers } from "@/common/helpers";
import { event_bus } from "@/main";
import {
  ATTACHMENT_ADDED,
  ATTACHMENT_SIGNED_POA_STATE_CHANGED,
  ATTACHMENT_UPDATED,
  ATTACHMENT_EXPERT_OPINION_ORDER_STATE_CHANGED,
} from "@/common/events";
import moment from "moment";
import { BDialogConfig } from "buefy/types/components";
import { AttachmentTag } from "@/common/enums";
import { TagChangedEvent } from "@/common/types";
import { ShowPdf } from "@/ui/components/detail";
import TagSelect from "@/ui/components/detail/attachments/TagSelect.vue";

function sortAttachments(list: AttachmentDTO[]): AttachmentDTO[] {
  const copy = list.map((obj) => ({ ...obj }));

  return copy.sort((a: AttachmentDTO, b: AttachmentDTO) =>
    moment(a.created).diff(moment(b.created))
  );
}

export default Vue.extend({
  name: "FileList",
  components: { TagSelect, ShowPdf },
  props: {
    caseId: {
      type: String,
      required: false,
      default: "",
    },
    customerEmailValid: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      files: [] as AttachmentDTO[],
      loading: false,
      showPdf: false,
      attachmentToShow: undefined as AttachmentDTO | undefined,
      AttachmentTag,
    };
  },
  watch: {
    files: {
      handler() {
        const poa = this.files.some(
          (file) => file.tag === AttachmentTag.POA_SIGNED
        );
        event_bus.$emit(ATTACHMENT_SIGNED_POA_STATE_CHANGED, poa);
        const expert = this.files.some(
          (file) => file.tag === AttachmentTag.EXPERT_OPINION_ORDER_SIGNED
        );
        event_bus.$emit(ATTACHMENT_EXPERT_OPINION_ORDER_STATE_CHANGED, expert);
      },
      deep: true,
    },
  },
  computed: {
    emailTooltip(): string {
      return this.customerEmailValid
        ? i18n.t("UI.tooltip.sendEmail")
        : i18n.t("UI.tooltip.sendEmailDisabled");
    },
  },
  methods: {
    formatDate(dateTime: Date): string {
      return moment(dateTime).format("DD MM YYYY HH:mm");
    },
    formatTooltip(file: AttachmentDTO): string {
      let res = "";
      if (file.tag !== AttachmentTag.NONE) {
        res = `${file.fileName} - `;
      }
      res += this.formatDate(file.created);
      return res;
    },
    getClass(tag: AttachmentTag): string {
      return tag === AttachmentTag.NONE
        ? "is-light-inverted"
        : "is-primary is-light";
    },
    async updateFiles() {
      this.loading = true;
      await new AttachmentHttpClient()
        .loadAttachments(this.caseId)
        .then((res) => {
          this.files = sortAttachments(res);
          this.loading = false;
        })
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.attachment.load"),
            err.message
          );
        });
    },
    downloadFile(file: AttachmentDTO): void {
      new AttachmentHttpClient()
        .downloadAttachment(file.attachmentId)
        .then((data) => {
          fileDownload(data, file.fileName);
        })
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.attachment.download"),
            err.message
          );
        });
    },
    sendDocument(file: AttachmentDTO) {
      if (!file.attachmentId || !this.customerEmailValid) {
        return;
      }

      const options = {
        title: i18n.t(`UI.dialog.sendDocumentToCustomer.title`),
        message: i18n.t(`UI.dialog.sendDocumentToCustomer.message`),
        type: "is-info",
        hasIcon: true,
        icon: "question-circle",
        confirmText: i18n.t(`UI.dialog.sendDocumentToCustomer.confirmText`),
        cancelText: i18n.t(`UI.dialog.sendDocumentToCustomer.cancelText`),
        onConfirm: () =>
          new AttachmentHttpClient()
            .sendDocumentToCustomer(file.attachmentId)
            .then(() => {
              BuefyHelpers.show_success_notification(
                this.$buefy,
                i18n.t("UI.message.success"),
                i18n.t("UI.message.documentSent")
              );
            })
            .catch((err) => {
              BuefyHelpers.show_request_exception_notification(
                this.$buefy,
                i18n.t("UI.error.attachment.sendDocument"),
                err.message
              );
            }),
      };
      this.$buefy.dialog.confirm(options);
    },
    confirmDeleteFile(file: AttachmentDTO) {
      const options: BDialogConfig = {
        title: i18n.t("UI.dialog.deleteAttachment.title"),
        message: i18n.t("UI.dialog.deleteAttachment.message"),
        confirmText: i18n.t("UI.dialog.deleteAttachment.confirmText"),
        cancelText: i18n.t("UI.dialog.deleteAttachment.cancelText"),
        type: "is-danger",
        hasIcon: true,
        icon: "exclamation-triangle",
        iconPack: "fa",
        ariaRole: "alertdialog",
        ariaModal: true,
        onConfirm: () => this.deleteFile(file),
      };

      this.$buefy.dialog.confirm(options);
    },
    async deleteFile(file: AttachmentDTO): Promise<void> {
      new AttachmentHttpClient()
        .deleteAttachment(file.attachmentId)
        .then((data) => {
          if (data) {
            this.updateFiles();
            BuefyHelpers.show_success_notification(
              this.$buefy,
              i18n.t("UI.message.success"),
              i18n.t("UI.message.attachmentDeleted", {
                fileName: file.fileName,
              })
            );
          }
        })
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.attachment.delete"),
            err.message
          );
        });
    },
    addFile(file: AttachmentDTO): void {
      this.files.push(file);
    },
    showFile(file: AttachmentDTO): void {
      this.showPdf = true;
      this.attachmentToShow = file;
    },
    handleClose() {
      this.showPdf = false;
      this.attachmentToShow = undefined;
    },
    isPdf(filename: string): boolean {
      return filename.toLowerCase().endsWith("pdf");
    },
    fileIcon(filename: string): string {
      if (this.isPdf(filename)) {
        return "file-pdf";
      }
      return "file";
    },
    async updateTag({ value, fileId }: TagChangedEvent) {
      await new AttachmentHttpClient()
        .updateTag(fileId, value)
        .then(() => this.updateFiles())
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.attachment.updateTag"),
            err.message
          );
        });
    },
    defaultAction(file: AttachmentDTO): void {
      if (this.isPdf(file.fileName)) {
        this.showFile(file);
      } else {
        this.downloadFile(file);
      }
    },
  },
  async created() {
    await this.updateFiles();
    event_bus.$on(ATTACHMENT_ADDED, () => {
      this.updateFiles();
    });
    event_bus.$on(ATTACHMENT_UPDATED, () => {
      this.updateFiles();
    });
  },
});
