import {
  Advisory,
  Country,
  DamageType,
  DecisionEnum,
  Kind,
  Ownership,
  RentalDecision,
  RepairDecision,
  Reporter,
  Roadworthiness,
  Status,
} from "@/common/enums";
import { AddressDTO, LocationDTO } from "@/common/dto";

export class CaseDTO {
  id: string;
  title: string;
  locationId: string;
  createdBy: string;
  creationDate: Date;
  lastModifiedBy: string;
  lastModifiedDate: Date;
  lastModifiedDateHistory: Date;
  fileSign: string;
  fileSignLawyer: string;
  status: Status;
  reporter: Reporter;
  kind: Kind;
  injuredParty: AddressDTO;
  injuredIban: string;
  injuredVatDeduction: DecisionEnum;
  legalInsurance: string;
  vehicleLicensePlate: string;
  vehicleInsuranceId: string;
  vehicleOwnership: Ownership;
  vehicleBank: string;
  roadworthy: Roadworthiness;
  opponentInsuranceId: string;
  opponentClaimNumber: string;
  insurancePolicy: string;
  opponentOwnerAddress: AddressDTO;
  driverIsOwner: DecisionEnum;
  opponentDriverAddress: AddressDTO;
  opponentLicensePlate: string;
  opponentLicensePlateCountry: Country;
  accidentDate: Date | null;
  accidentPlace: string;
  accidentCourse: string;
  accidentNational: DecisionEnum;
  accidentCountry: Country;
  policeAlerted: DecisionEnum;
  policeInspection: string;
  policeReference: string;
  insuranceContacted: DecisionEnum;
  rentalWanted: RentalDecision;
  repairWanted: RepairDecision;
  repairDate: Date | null;
  witnessAvailable: DecisionEnum;
  advisory: Advisory;
  expertOffice: string;
  expertEmployee: string;
  expertReference: string;
  witnessAddress: AddressDTO;
  additionalInfos: string;
  physicalInjury: DecisionEnum;
  version: number;
  damageType: DamageType;
  tags: string[];
  customerReference: string;
  vehicleId: string;
  legalInsuranceId: string;
  legalInsuranceAvailable: DecisionEnum;
  caseHandler: string;
  releaseDate: Date | null;
  carManufacturer: string;
  carModel: string;
  selfInvolvementPartial: string;
  selfInvolvementFull: string;
  expertOfficeCode: LocationDTO;
  expertEmployeeCode: string;

  constructor(
    id: string,
    title: string,
    locationId: string,
    createdBy: string,
    creationDate: Date,
    lastModifiedBy: string,
    lastModifiedDate: Date,
    lastModifiedDateHistory: Date,
    fileSign: string,
    fileSignLawyer: string,
    status: Status,
    reporter: Reporter,
    kind: Kind,
    injuredParty: AddressDTO,
    injuredIban: string,
    injuredVatDeduction: DecisionEnum,
    legalInsurance: string,
    vehicleInsuranceId: string,
    vehicleLicensePlate: string,
    vehicleOwnership: Ownership,
    vehicleBank: string,
    roadworthy: Roadworthiness,
    opponentInsuranceId: string,
    opponentClaimNumber: string,
    insurancePolicy: string,
    opponentOwnerAddress: AddressDTO,
    driverIsOwner: DecisionEnum,
    opponentDriverAddress: AddressDTO,
    opponentLicensePlate: string,
    opponentLicensePlateCountry: Country,
    accidentDate: Date | null,
    accidentPlace: string,
    accidentCourse: string,
    accidentNational: DecisionEnum,
    accidentCountry: Country,
    policeAlerted: DecisionEnum,
    policeInspection: string,
    policeReference: string,
    insuranceContacted: DecisionEnum,
    rentalWanted: RentalDecision,
    repairWanted: RepairDecision,
    repairDate: Date | null,
    witnessAvailable: DecisionEnum,
    advisory: Advisory,
    expertOffice: string,
    expertEmployee: string,
    expertReference: string,
    witnessAddress: AddressDTO,
    additionalInfos: string,
    physicalInjury: DecisionEnum,
    version: number,
    damageType: DamageType,
    tags: string[],
    customerReference: string,
    vehicleId: string,
    legalInsuranceId: string,
    legalInsuranceAvailable: DecisionEnum,
    caseHandler: string,
    releaseDate: Date | null,
    carManufacturer: string,
    carModel: string,
    selfInvolvementPartial: string,
    selfInvolvementFull: string,
    expertOfficeCode: LocationDTO,
    expertEmployeeCode: string
  ) {
    this.id = id;
    this.title = title;
    this.locationId = locationId;
    this.createdBy = createdBy;
    this.creationDate = creationDate;
    this.lastModifiedBy = lastModifiedBy;
    this.lastModifiedDate = lastModifiedDate;
    this.lastModifiedDateHistory = lastModifiedDateHistory;
    this.fileSign = fileSign;
    this.fileSignLawyer = fileSignLawyer;
    this.status = status;
    this.reporter = reporter;
    this.kind = kind;
    this.injuredParty = injuredParty;
    this.injuredIban = injuredIban;
    this.injuredVatDeduction = injuredVatDeduction;
    this.legalInsurance = legalInsurance;
    this.vehicleInsuranceId = vehicleInsuranceId;
    this.vehicleOwnership = vehicleOwnership;
    this.vehicleBank = vehicleBank;
    this.roadworthy = roadworthy;
    this.opponentInsuranceId = opponentInsuranceId;
    this.opponentClaimNumber = opponentClaimNumber;
    this.insurancePolicy = insurancePolicy;
    this.opponentOwnerAddress = opponentOwnerAddress;
    this.driverIsOwner = driverIsOwner;
    this.opponentDriverAddress = opponentDriverAddress;
    this.opponentLicensePlate = opponentLicensePlate;
    this.opponentLicensePlateCountry = opponentLicensePlateCountry;
    this.accidentDate = accidentDate;
    this.accidentPlace = accidentPlace;
    this.accidentCourse = accidentCourse;
    this.accidentNational = accidentNational;
    this.accidentCountry = accidentCountry;
    this.policeAlerted = policeAlerted;
    this.policeInspection = policeInspection;
    this.policeReference = policeReference;
    this.insuranceContacted = insuranceContacted;
    this.rentalWanted = rentalWanted;
    this.repairWanted = repairWanted;
    this.repairDate = repairDate;
    this.witnessAvailable = witnessAvailable;
    this.advisory = advisory;
    this.expertOffice = expertOffice;
    this.expertEmployee = expertEmployee;
    this.expertReference = expertReference;
    this.witnessAddress = witnessAddress;
    this.additionalInfos = additionalInfos;
    this.physicalInjury = physicalInjury;
    this.version = version;
    this.damageType = damageType;
    this.vehicleLicensePlate = vehicleLicensePlate;
    this.tags = tags;
    this.customerReference = customerReference;
    this.vehicleId = vehicleId;
    this.legalInsuranceId = legalInsuranceId;
    this.legalInsuranceAvailable = legalInsuranceAvailable;
    this.caseHandler = caseHandler;
    this.releaseDate = releaseDate;
    this.carManufacturer = carManufacturer;
    this.carModel = carModel;
    this.selfInvolvementPartial = selfInvolvementPartial;
    this.selfInvolvementFull = selfInvolvementFull;
    this.expertOfficeCode = expertOfficeCode;
    this.expertEmployeeCode = expertEmployeeCode;
  }
}
