
import Vue from "vue";
import { CaseDTO, LocationDTO, UserDetails } from "@/common/dto";
import { mapGetters } from "vuex";
import { userModuleMeta } from "@/ui/store/user";
import Advisory from "../../../../common/enums/Advisory";
import { DataHttpClient } from "@/common/http";
import { BuefyHelpers } from "@/common/helpers";
import i18n from "@/ui/i18n";
import ExpertEmployeeSelect from "@/ui/components/common/forms/ExpertEmployeeSelect.vue";
import AdvisorySelect from "@/ui/components/common/forms/AdvisorySelect.vue";
import BaseInput from "@/ui/components/common/forms/BaseInput.vue";
import ExpertSelect from "@/ui/components/common/forms/ExpertSelect.vue";

export default Vue.extend({
  components: {
    ExpertEmployeeSelect,
    AdvisorySelect,
    BaseInput,
    ExpertSelect,
  },
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    value: {
      type: [CaseDTO],
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      appraisers: [] as LocationDTO[],
      UserDetails,
    };
  },
  methods: {
    addOtherSuffix(label: string, value: string): string {
      if (value === "OTHER") {
        return label + " (" + i18n.t("UI.enum.general.OTHER") + ")";
      }
      return label;
    },
    async loadAppraisers() {
      await new DataHttpClient()
        .availableAppraisers(this.localValue.locationId)
        .then((res) => {
          this.appraisers = res;
        })
        .catch((err) => {
          BuefyHelpers.show_request_exception_notification(
            this.$buefy,
            i18n.t("UI.error.data.appraisers"),
            err.message
          );
        });
    },
  },
  computed: {
    ...mapGetters({
      details: userModuleMeta.getters.getDetails,
    }),
    localValue: {
      get: function (): CaseDTO {
        return this.value;
      },
      set: function (value: CaseDTO) {
        this.$emit("valueChanged", value);
      },
    },
    expert_fields_needed(): boolean {
      return ![Advisory.NONE, Advisory.NO, Advisory.COST_ESTIMATE].includes(
        this.localValue.advisory
      );
    },
    locationCode(): string {
      if (this.localValue.expertOfficeCode == null) {
        return "";
      }
      if (
        this.localValue.expertOfficeCode.locationCode == null ||
        this.localValue.expertOfficeCode.locationCode == ""
      ) {
        return "OTHER";
      }
      return this.localValue.expertOfficeCode.locationCode;
    },
  },
  async created() {
    await this.loadAppraisers();
  },
  watch: {
    "localValue.locationId": async function () {
      this.localValue.expertOfficeCode = LocationDTO.empty();
      this.localValue.expertOffice = "";
      this.localValue.expertEmployeeCode = "";
      this.localValue.expertEmployee = "";
      await this.loadAppraisers();
    },
  },
});
