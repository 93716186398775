enum AttachmentTag {
  NONE = "NONE",
  POA = "POA",
  POA_SIGNED = "POA_SIGNED",
  PICTURE = "PICTURE",
  ADVISORY = "ADVISORY",
  INVOICE_ADVISORY = "INVOICE_ADVISORY",
  INVOICE_REPAIR = "INVOICE_REPAIR",
  INVOICE_RENTAL = "INVOICE_RENTAL",
  QUANTIFICATION = "QUANTIFICATION",
  LETTER_INSURANCE = "LETTER_INSURANCE",
  LETTER_POLICE = "LETTER_POLICE",
  TRANSPORTATION_DOCUMENTS = "TRANSPORTATION_DOCUMENTS",
  EXPERT_ASSISTANCE_COSTS = "EXPERT_ASSISTANCE_COSTS",
  EXPERT_OPINION_ORDER = "EXPERT_OPINION_ORDER",
  EXPERT_OPINION_ORDER_SIGNED = "EXPERT_OPINION_ORDER_SIGNED",
  OTHER = "OTHER",
}

export default AttachmentTag;
