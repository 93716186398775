// Attachments
const ATTACHMENT_ADDED = "ATTACHMENT_ADDED";
const ATTACHMENT_SIGNED_POA_STATE_CHANGED =
  "ATTACHMENT_SIGNED_POA_STATE_CHANGED";
const ATTACHMENT_EXPERT_OPINION_ORDER_STATE_CHANGED =
  "ATTACHMENT_EXPERT_OPINION_ORDER_STATE_CHANGED";
const ATTACHMENT_UPDATED = "ATTACHMENT_UPDATED";
const SAVE_ATTACHMENT = "SAVE_ATTACHMENT";
const POA_SIGNED = "POA_SIGNED";

// PDF-Viewer
const CLOSE_PDF_VIEWER = "CLOSE_PDF_VIEWER";

// Pagination
const PER_PAGE_CHANGED = "perPageChanged";

// Sorting
const SORT_FIELD_CHANGED = "SORT_FIELD_CHANGED";
const SORT_DIRECTION_CHANGED = "SORT_DIRECTION_CHANGED";
const CASE_FILTER_INITIALIZED = "CASE_FILTER_INITIALIZED";
const REPAIR_DATE_MISSING_ADDED = "REPAIR_DATE_MISSING_ADDED";

// Appraiser

export {
  ATTACHMENT_ADDED,
  ATTACHMENT_SIGNED_POA_STATE_CHANGED,
  ATTACHMENT_UPDATED,
  SAVE_ATTACHMENT,
  CLOSE_PDF_VIEWER,
  PER_PAGE_CHANGED,
  SORT_DIRECTION_CHANGED,
  SORT_FIELD_CHANGED,
  CASE_FILTER_INITIALIZED,
  REPAIR_DATE_MISSING_ADDED,
  POA_SIGNED,
  ATTACHMENT_EXPERT_OPINION_ORDER_STATE_CHANGED,
};
