enum Status {
  WORK_IN_PROGRESS = "WORK_IN_PROGRESS",
  RELEASED = "RELEASED",
  HANDED_OVER_APPRAISER = "HANDED_OVER_APPRAISER",
  CLOSED = "CLOSED",
  HIDDEN = "HIDDEN",
  CANCELLED = "CANCELLED",
}

export default Status;
