
import Vue, { PropType } from "vue";
import { DecisionEnum, UserRole } from "@/common/enums";

export default Vue.extend({
  components: {},
  model: {
    prop: "value",
    event: "valueChanged",
  },
  props: {
    value: {
      type: String as PropType<UserRole>,
      default: UserRole.NONE,
    },
  },
  data() {
    return {
      UserRole,
    };
  },
  computed: {
    localValue: {
      get: function (): string {
        if ((this.value as string) === "") {
          return DecisionEnum.NONE;
        }
        return this.value;
      },
      set: function (value: string) {
        this.$emit("valueChanged", value);
      },
    },
  },
});
