
import Vue, { PropType } from "vue";
import { UserRole } from "@/common/enums";
import i18n from "@/ui/i18n";

export default Vue.extend({
  components: {},
  props: {
    value: {
      type: String as PropType<UserRole>,
      default: UserRole.NONE,
    },
  },
  data() {
    return {};
  },
  computed: {
    translation_key(): string {
      if (
        !i18n.te("UI.enum.user_role." + this.value) ||
        this.value === UserRole.NONE
      ) {
        return "UI.message.notAvailable";
      }
      return "UI.enum.user_role." + this.value;
    },
    css_class(): string {
      if (this.value === UserRole.USER) {
        return "is-warning";
      } else if (this.value === UserRole.ADMIN) {
        return "is-danger";
      } else if (this.value === UserRole.APPRAISER) {
        return "is-success";
      } else if (this.value === UserRole.CASE_HANDLER) {
        return "is-primary";
      }
      return "";
    },
  },
});
